const onKeyDownHandler = (keys: string[]) => {
  return (callback: Function) => {
    return (event: Event, preventDefault?: boolean) => {
      if (keys.includes((event as KeyboardEvent).key)) {
        callback(event);

        if (preventDefault) {
          event.preventDefault();
          return false;
        }
      }
    };
  };
};

export { onKeyDownHandler };
