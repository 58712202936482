import styled from "styled-components";
import { colors } from "style";

const FooterLink = styled("a")`
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  padding: 2px;
  transition: box-shadow 1s cubic-bezier(0.2, 1, 0.3, 1);

  &:focus-visible {
    outline-style: none;
    border-color: transparent;
    box-shadow: ${colors.white} 0px 0px 0px 4px;
  }
`;

export { FooterLink };
