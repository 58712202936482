import styled from "styled-components";
import { colors, sizes } from "style";

const HamburgerWrapper = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  ${sizes.query.touch} {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      background-color: ${colors.primary};
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
  }
`;
export default HamburgerWrapper;
