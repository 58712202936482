import { sizes } from "style";
import styled from "styled-components";

const ShowMobile = styled("span")`
  ${sizes.toggle.showMobile}
`;

const HideMobile = styled("span")`
  ${sizes.toggle.hideMobile}
`;

const HideTouch = styled("span")`
  ${sizes.toggle.hideTouch}
`;

const ShowTouch = styled("span")`
  ${sizes.toggle.showTouch}
`;

const ShowDesktop = styled("span")`
  ${sizes.toggle.showDesktop}
`;

const HideDesktop = styled("span")`
  ${sizes.toggle.hideDesktop}
`;

export {
  ShowMobile,
  HideMobile,
  ShowTouch,
  HideTouch,
  ShowDesktop,
  HideDesktop,
};
