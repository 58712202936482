import styled from "styled-components";
import { BreakpointWidths } from "components";

const FooterRow = styled(BreakpointWidths as any)`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 100%;
  padding: 0;
  * {
    align-self: center;
  }
`;
export default FooterRow;
