import styled from "styled-components";
import React from "react";

import { animation, colors, effects, sizes } from "style";
import { CloseX } from "icons";

const { focus } = effects;

const StyledClose = styled(CloseX)`
  width: 1em;
  height: 1em;
  position: absolute;
  right: 1em;
  top: 1em;
  overflow: hidden;
  text-indent: 1em;
  font-size: 0.75em;
  border: none;
  background: transparent;
  color: transparent;
  cursor: pointer;
  transition: all ${animation.transition};
  fill: ${colors.white};

  path {
    transition: all ${animation.transition};
  }

  &:hover {
    transform: rotate(90deg);
    path {
      fill: ${colors.tertiary};
    }
  }

  ${focus}

  ${sizes.query.touch} {
    transform: scale(2);
    right: 4em;
    top: 4em;

    &:hover {
      transform: scale(2) rotate(90deg);
    }
  }
`;

const MenuCloseX = React.forwardRef((props: any, ref) => {
  return <StyledClose {...props} {...{ ref }} />;
});

export { MenuCloseX };
