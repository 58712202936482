import styled from "styled-components";

const StyledIconWrappers = styled("ul")`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    margin-left: 20px;
  }
`;

export default StyledIconWrappers;
