import React, { Dispatch, createContext, SetStateAction } from "react";

type StoreContextType = {
  setShowMenu?: Dispatch<SetStateAction<boolean>>;
  showMenu?: boolean;
};

const AppStoreContext = createContext<StoreContextType>({
  showMenu: false,
  setShowMenu: undefined,
});

const AppStore = ({ children }: any) => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  return (
    <AppStoreContext.Provider value={{ showMenu, setShowMenu }}>
      {children}
    </AppStoreContext.Provider>
  );
};

export { AppStoreContext };
export default AppStore;
