import * as React from "react";
import styled from "styled-components";

import { Row, Column, ContentWrapper } from "../layout";
import { H4, Spacer } from "components";

const DemoRow = styled(Row as any)`
  background: lightGrey;
`;

const DemoColumn = styled(Column as any)`
  text-align: center;
  background: red;
  color: white;
`;

const LayoutDemo = () => (
  <>
    <ContentWrapper>
      <Spacer size="xxl" />
      <H4 style={{ color: "white" }}>Single Columns</H4>
      <Spacer />
      <DemoRow>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={1} desktop={1} tablet={1}>
          Column
        </DemoColumn>
      </DemoRow>
      <Spacer />
      <H4 style={{ color: "white" }}>Two Column</H4>
      <Spacer />
      <DemoRow>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={2} desktop={2} tablet={2}>
          Column
        </DemoColumn>
      </DemoRow>
      <Spacer />
      <H4 style={{ color: "white" }}>Three Column</H4>
      <Spacer />
      <DemoRow>
        <DemoColumn largeDesktop={3} desktop={3} tablet={3}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={3} desktop={3} tablet={3}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={3} desktop={3} tablet={3}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={3} desktop={3} tablet={3}>
          Column
        </DemoColumn>
      </DemoRow>
      <Spacer />
      <H4 style={{ color: "white" }}>Four Column</H4>
      <Spacer />
      <DemoRow>
        <DemoColumn largeDesktop={4} desktop={4} tablet={4}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={4} desktop={4} tablet={4}>
          Column
        </DemoColumn>
        <DemoColumn largeDesktop={4} desktop={4} tablet={4}>
          Column
        </DemoColumn>
      </DemoRow>
      <Spacer />
      <H4 style={{ color: "white" }}>Of Columns</H4>
      <Spacer />
      <DemoRow>
        <DemoColumn largeDesktop={4}>
          <DemoRow>
            <DemoColumn largeDesktop="2-4">Column 2 of 4</DemoColumn>
            <DemoColumn largeDesktop="2-4">Column 2 of 4</DemoColumn>
          </DemoRow>
        </DemoColumn>
        <DemoColumn largeDesktop={4}>
          <DemoRow>
            <DemoColumn largeDesktop="1-4">Column 1 of 4</DemoColumn>
            <DemoColumn largeDesktop="3-4">Column 3 of 4</DemoColumn>
          </DemoRow>
        </DemoColumn>
        <DemoColumn largeDesktop={4}>
          <DemoRow>
            <DemoColumn largeDesktop="1-4">Column 1 of 4</DemoColumn>
            <DemoColumn largeDesktop="1-4">Column 1 of 4</DemoColumn>
            <DemoColumn largeDesktop="1-4">Column 1 of 4</DemoColumn>
            <DemoColumn largeDesktop="1-4">Column 1 of 4</DemoColumn>
          </DemoRow>
        </DemoColumn>
      </DemoRow>
      <Spacer size="xxl" />
    </ContentWrapper>
  </>
);

export default LayoutDemo;
