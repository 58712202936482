import { NavLink as ReactNavLink } from "react-router-dom";
import { colors, effects, sizes, typography } from "style";
import styled from "styled-components";

import { H4Style } from "../../components/Typography";

const { beforeAfter, activeHover, focus } = effects;
const { sizing, font } = typography;

const NavLink = styled(ReactNavLink)`
  font-family: ${font.nav};
  font-size: ${sizing.nav};
  font-weight: 700;
  color: ${colors.textHighlight};
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin-top: -29px;
  margin-bottom: -16px;

  &.selected span:before {
    ${activeHover}
  }

  ${focus}

  span {
    position: relative;

    &:before {
      left: -20px;
      height: 3px;
      width: 10px;
      top: 9px;
      background: ${colors.white};
      ${beforeAfter}
    }

    &:after {
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${colors.textHighlight};
      z-index: -1;
      ${beforeAfter}
    }

    &:hover:after {
      ${activeHover}
    }

    ${sizes.query.touch} {
      font-size: ${sizing.mobile.nav};

      &:before {
        top: 16px;
        left: -34px;
        height: 6px;
        width: 23px;
      }

      &:before,
      &:after {
        height: 6px;
      }
    }
  }
  ${sizes.query.touch} {
    width: 100%;
  }
`;

const MobileTitleText = styled("h4")`
  ${H4Style};
  color: ${colors.white};
  text-transform: uppercase;
`;

export { NavLink, MobileTitleText };
