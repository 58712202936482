import React, { useState, useEffect, useRef } from "react";
import LazyImageBackground from "./LazyImageBackground";
import StyledLazyImage from "./StyledLazyImage";

type Props = Partial<
  Pick<HTMLImageElement, "src" | "alt" | "className" | "height" | "width">
>;

function LazyImage({ className, src, alt, ...rest }: Props) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>();

  useEffect(() => {
    imgRef.current?.complete && setLoaded(true);
  }, []);

  return (
    <LazyImageBackground>
      <StyledLazyImage
        {...rest}
        loading="lazy"
        loaded={loaded}
        src={src}
        alt={alt}
        // @ts-ignore
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={className ? className : ""}
      />
    </LazyImageBackground>
  );
}
export default LazyImage;
