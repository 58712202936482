import styled from "styled-components";
import ContentWrapper from "./ContentWrapper";

const FlexContent = styled(ContentWrapper as any)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px; // use style
`;

export default FlexContent;
