import { sizes } from "style";
import styled from "styled-components";
import BreakpointWidths from "./BreakpointWidths";

const ContentWrapper = styled(BreakpointWidths as any)`
  margin: auto;
  min-height: 100vh;
  width: 100%;
  padding-top: ${sizes.contentTop}px;
  padding-bottom: ${sizes.footer}px;
`;

export default ContentWrapper;
