import styled from "styled-components";

const StyledLazyImage = styled.img<{ loaded: boolean }>`
  display: block;
  opacity: ${(props) => (props.loaded === true ? 1 : 0)}0;
  transition: opacity 0.5s ease-in-out;
  max-width: 100%;
  heigth: auto;
`;

export default StyledLazyImage;
