import { createGlobalStyle } from "styled-components";

import typography from "./typography";
import colors from "./colors";

const GlobalStyle = createGlobalStyle`
    html{
        background: black;
        height: 100%;
        font-size: ${typography.sizing.base};
    }
    body {
        min-height: 100%;
    }
    *{ 
        box-sizing: border-box;
        position: relative;
        margin: 0; 
        padding: 0; 
    }

    .lazyload-wrapper{
        img{
            width:100%;
            height:auto;
        }
    }

    .lazyload-placeholder{
        background: ${colors.almostBlack};
        width: 100%;
        padding-bottom: 56.25%;
    }
`;

export default GlobalStyle;
