import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GlobalStyle } from "style";
import { Footer, LayoutDemo, Navigation, TypeDemo, Loading } from "components";
import AppWrapper from "./AppWrapper";
import Content from "./Content";
import Main from "./Main";
import AppStore from "./AppStore";

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Portfolio = React.lazy(() => import("./pages/Portfolio"));
const Press = React.lazy(() => import("./pages/Press"));
const Project = React.lazy(() => import("./pages/Project"));
const Error404 = React.lazy(() => import("./pages/Error404"));

const App = () => (
  <AppStore>
    <AppWrapper>
      <GlobalStyle />
      <BrowserRouter>
        <Content>
          <Main id="main">
            <React.Suspense fallback={<Loading />}>
              <Routes>
                <Route key="home" path="/" element={<Home />} />
                <Route key="about" path="/about" element={<About />} />
                <Route
                  key="portfolio"
                  path="/portfolio"
                  element={<Portfolio />}
                />
                <Route
                  key="project"
                  path="/portfolio/project/:project"
                  element={<Project />}
                />
                <Route key="press" path="/press" element={<Press />} />
                <Route key="type" path="/typedemo" element={<TypeDemo />} />
                <Route
                  key="layout"
                  path="/layoutdemo"
                  element={<LayoutDemo />}
                />
                <Route key="404" path="*" element={<Error404 />} />
              </Routes>
              <Footer />
            </React.Suspense>
          </Main>
          <Navigation />
        </Content>
      </BrowserRouter>
    </AppWrapper>
  </AppStore>
);

export default App;
