import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "style";
import { FooterType } from "./Footer";
import { FooterLink } from "./FooterLink";

const FooterIcon = ({ url, icon }: FooterType) => (
  <li>
    <FooterLink href={url} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} size="2x" color={colors.white} />
    </FooterLink>
  </li>
);

export default FooterIcon;
