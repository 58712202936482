import styled from "styled-components";
import { colors, sizes } from "style";

const StyledFooter = styled("div")`
  height: ${sizes.footer}px;
  margin-top: -${sizes.footer}px;
  width: 100%;
  background: ${colors.backgroundTertiary};
`;
export default StyledFooter;
