import styled, { css } from "styled-components";
import { Props } from "./Navigation";

const NavList = styled("ul")<Props>`
  height: 100%;
  transform: translate3d(0, 100%, 0);
  transition: transform 0s 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  ${({ showMenu }) =>
    showMenu &&
    css`
      transform: translate3d(0, 0, 0);
      transition: transform 0.8s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    `}
`;
export default NavList;
