import styled, { css } from "styled-components";
import { colors, sizes, typography } from "../../style";

/**
 * Global
 */
const { sizing, font, lineHeight } = typography;

const IntroStyle = css`
  font-family: ${font.intro};
  font-size: ${sizing.h3};
  line-height: ${lineHeight.header};

  ${sizes.query.mobile} {
    font-size: ${sizing.h5};
  }
`;

const H1Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h1};
  white-space: nowrap;
  margin-top: -44px;
  margin-bottom: -29px;
  line-height: ${lineHeight.header};
`;

const H1 = styled("h1")`
  ${H1Style}
`;

const H2Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h2};
  margin-top: -29px;
  margin-bottom: -20px;
  line-height: ${lineHeight.header};
`;

const H2 = styled("h2")`
  ${H2Style}
`;

const H3Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h3};
  margin-top: -17px;
  margin-bottom: -12px;
  line-height: ${lineHeight.header};
`;

const H3 = styled("h3")`
  ${H3Style}
`;

const H4Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h4};
  margin-top: -12px;
  margin-bottom: -8px;
  line-height: ${lineHeight.header};
`;

const H4 = styled("h4")`
  ${H4Style}
`;

const H5Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h5};
  margin-top: -6px;
  margin-bottom: -5px;
  line-height: ${lineHeight.header};
`;

const H5 = styled("h5")`
  ${H5Style}
`;

const H6Style = css`
  font-family: ${font.header};
  font-size: ${sizing.h6};
  margin-top: -9px;
  margin-bottom: -6px;
  line-height: ${lineHeight.header};
`;

const H6 = styled("h6")`
  ${H6Style}
`;

const ParagraphStyle = css`
  font-family: ${font.body};
  font-size: ${sizing.p};
  margin-top: -5px;
  margin-bottom: -6px;
  line-height: ${lineHeight.base};
  color: ${colors.textPrimary};
`;

const Paragraph = styled("p")`
  ${ParagraphStyle}
`;

const ListItem = styled("p")`
  ${ParagraphStyle}

  ${sizes.query.mobile} {
    font-size: ${sizing.h5};
  }
`;

const SmallStyle = css`
  display: block;
  font-family: ${font.small};
  font-size: ${sizing.small};
  margin-top: -3px;
  margin-bottom: -3px;
  line-height: ${lineHeight.base};
`;

const Small = styled("span")`
  ${SmallStyle}
`;

const FooterStyle = css`
  color: ${colors.white};
  font-family: ${font.footer};
  font-size: ${sizing.p};
  text-transform: uppercase;

  ${sizes.query.mobile} {
    font-size: ${sizing.h6};
  }
`;

const FooterText = styled("h4")`
  ${FooterStyle}
`;

const NavLinkStyle = css`
  font-family: ${font.nav};
  font-size: ${sizing.nav};
  font-weight: 700;
  color: ${colors.textHighlight};
  text-decoration: none;
  text-transform: uppercase;
`;

const AdditionalInfo = styled("h3")`
  ${H5Style}
  color: ${colors.textHighlight};
  text-transform: uppercase;

  &:before {
    ${sizes.query.desktop} {
      content: "";
      display: block;
      left: -20px;
      height: 3px;
      width: 10px;
      top: 9px;
      background: ${colors.white};
      position: absolute;
    }
  }
  ${sizes.query.mobile} {
    font-size: ${sizing.h5};
  }
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  IntroStyle,
  Paragraph,
  ListItem,
  Small,
  H1Style,
  H2Style,
  H3Style,
  H4Style,
  H5Style,
  H6Style,
  ParagraphStyle,
  SmallStyle,
  FooterStyle,
  FooterText,
  NavLinkStyle,
  AdditionalInfo,
};
