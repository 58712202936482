import styled, { css } from "styled-components";
import { colors, sizes } from "style";

const NavigationFull = styled("div")<any>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 300px;
  height: 100%;
  background: ${colors.almostBlack};
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  ${sizes.query.touch} {
    width: 100%;
  }

  ${({ showMenu }) =>
    showMenu &&
    css`
      transform: translate3d(0, 0, 0);
      transition: transform 0.8s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    `}
`;

export default NavigationFull;
