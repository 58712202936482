// import React from "react";
import styled from "styled-components";

import { sizes } from "style";

type Size = keyof typeof sizes.spacing;
type SizingType = { size?: Size };

const Spacer = styled("div")<SizingType>`
  height: ${({ size = "md" }: SizingType) => sizes?.spacing[size] + "px; "};
  width: 100%;
`;

export default Spacer;
