import styled from "styled-components";
import { HamburgerSVG } from "icons";
import { animation, colors, effects, sizes } from "style";

const { focus } = effects;

const Hamburger = styled(HamburgerSVG)`
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  margin: 1em;
  padding: 0;
  width: 2.5em;
  height: 2.25em;
  border: none;
  text-indent: 2.5em;
  font-size: 1.5em;
  color: transparent;
  background: transparent;
  left: 15px;
  top: 15px;
  transition: all ${animation.transition};

  ${sizes.query.touch} {
    left: initial;
    right: 0;
    top: 0;
  }

  path {
    fill: ${colors.white};
    transition: all ${animation.transition};
  }

  &:hover {
    transform: scale(1.05);
    path {
      fill: ${colors.tertiary};
    }
  }

  ${focus}
`;

export default Hamburger;
