import { css } from "styled-components";
import { colors } from "style";

const activeHover = css`
  opacity: 1;
  transform: scaleX(1);
  transform-origin: 0 50%;
  transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear;
`;

const beforeAfter = css`
  content: " ";
  position: absolute;
  opacity: 0;
  transform: scale3d(0.001, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1),
    opacity 0.1s 0.2s linear;
`;

const focus = `
  outline: none;
  transition: box-shadow 1s cubic-bezier(0.2, 1, 0.3, 1);

  &:focus-visible {
    outline-style: none;  
    border-color: transparent;
    box-shadow: ${colors.highlight} 0px 0px 0px 4px;
  }
`;

export { activeHover, beforeAfter, focus };
