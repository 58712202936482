import { sizes } from "style";
import styled from "styled-components";

const { query, grid } = sizes;

const BreakpointWidths = styled("div")`
  margin: auto;

  ${query.mobile} {
    max-width: ${grid.mobile.maxWidth};
  }
  ${query.touch} {
    max-width: ${grid.tablet.maxWidth};
  }
  ${query.desktop} {
    max-width: ${grid.desktop.maxWidth}px;
  }
  ${query.largeDesktop} {
    max-width: ${grid.largeDesktop.maxWidth}px;
  }
`;

export default BreakpointWidths;
