import React from "react";

import { NavLink, MobileTitleText } from "./NavigationTypography";
import { onKeyDownHandler } from "../../utils";
import HamburgerWrapper from "./HamburgerWrapper";
import Hamburger from "./Hamburger";
import { MenuCloseX } from "./MenuCloseX";
import NavigationFull from "./NavigationFull";
import NavWrapper from "./NavWrapper";
import NavList from "./NavList";
import NavItem from "./NavItem";
import { AppStoreContext } from "../../AppStore";
import { HideDesktop } from "components/layout";
import { MobileTitle } from "./MobileTitle";

export type Props = {
  index?: number;
  showMenu?: boolean;
};

const links = [
  { to: "/", display: "Home" },
  { to: "/about", display: "About" },
  { to: "/portfolio", display: "Portfolio" },
  { to: "/press", display: "Press" },
];

const Navigation = () => {
  const { setShowMenu, showMenu } = React.useContext(AppStoreContext);
  const MenuClose = React.useRef() as React.MutableRefObject<HTMLHtmlElement>;
  const showMenuTrue = () => setShowMenu?.(true);
  const showMenuFalse = () => setShowMenu?.(false);
  const hamburgerKeyDown = onKeyDownHandler(["Enter"])(showMenuTrue);
  const closeKeyDown = onKeyDownHandler(["Enter"])(showMenuFalse);
  const MenuCloseRef = React.useRef<HTMLHtmlElement>();

  const bodyClick = React.useCallback(
    ({ target }: any) => {
      !MenuClose?.current?.contains(target) && showMenu && setShowMenu?.(false);
    },
    [showMenu, setShowMenu, MenuClose]
  );

  React.useEffect(() => {
    document.querySelector("#root")?.addEventListener("click", bodyClick);

    return () =>
      document.querySelector("#root")?.removeEventListener("click", bodyClick);
  }, [showMenu, MenuClose, bodyClick]);

  React.useEffect(() => {
    showMenu && MenuCloseRef?.current?.focus();
  }, [showMenu, MenuCloseRef]);

  return (
    <>
      <NavigationFull showMenu={showMenu} ref={MenuClose}>
        <NavWrapper>
          <NavList showMenu={showMenu}>
            {links.map(({ to, display }, idx) => (
              <NavItem
                showMenu={showMenu}
                key={display}
                index={idx}
                onClick={showMenuFalse}
                //@ts-ignore: fix type later
                onKeyDown={closeKeyDown}
                // tabIndex={showMenu ? 0 : -1}
              >
                <NavLink
                  to={to}
                  className={(isActive) =>
                    "nav-link" + (isActive ? " selected" : "")
                  }
                >
                  <span>{display}</span>
                </NavLink>
              </NavItem>
            ))}
          </NavList>
        </NavWrapper>
        <MenuCloseX
          onClick={showMenuFalse}
          onKeyDown={closeKeyDown}
          tabIndex={0}
          ref={MenuCloseRef}
        />
      </NavigationFull>

      <HamburgerWrapper>
        <Hamburger
          onClick={showMenuTrue}
          onKeyDown={hamburgerKeyDown}
          tabIndex={0}
        >
          Open Menu
        </Hamburger>
      </HamburgerWrapper>
      <MobileTitle>
        <HideDesktop>
          <MobileTitleText>Matt Lohmann</MobileTitleText>
        </HideDesktop>
      </MobileTitle>
    </>
  );
};

export default Navigation;
