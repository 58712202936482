import React from "react";
import { sizes } from "style";
import styled, { css } from "styled-components";

import { AppStoreContext } from "AppStore";

const { query } = sizes;

const StyledMain = styled("main")<{ showMenu?: boolean }>`
  min-height: 100vh;

  &:before {
    content: "";
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s linear;
  }
  ${({ showMenu }) =>
    showMenu &&
    css`
      &:before {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    `}

  ${query.touch} {
    overflow-x: hidden;
  }
`;

const Main = (props: any) => {
  const { showMenu } = React.useContext(AppStoreContext);

  return <StyledMain {...props} showMenu={showMenu} />;
};
export default Main;
