const insertNBSP = () => {
  const nbspSelectors = [
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "blockquote",
  ];

  const nbspWordMin = 6;

  nbspSelectors.forEach(function (selector) {
    const nbspElements = document.querySelectorAll(selector);
    nbspElements.forEach(function ({ innerHTML, textContent }) {
      if (innerHTML) {
        innerHTML.replaceAll("&nbsp", "");
      }
      if (textContent) {
        const wordCount = textContent.split(" ").length;
        if (wordCount >= nbspWordMin) {
          innerHTML = textContent.replace(/ ([^ ]*)$/, "&nbsp;$1");
        }
      }
    });
  });
};

export { insertNBSP };
