export type GridUnit = {
  maxWidth: number | string;
  gutter: number;
  columns: number;
  columnWidth: number;
};

export type Devices = "mobile" | "tablet" | "desktop" | "largeDesktop";
export type Grids = Record<Devices, GridUnit>;
export type DisplaySize = "xs" | "sm" | "md" | "lg" | "xl";
export type DisplaySizeType = { size?: DisplaySize };

const device = {
  mobile: { width: 719, margin: 12 },
  tablet: { width: 720, margin: 24 },
  touch: { width: 959 },
  desktop: { width: 960 },
  largeDesktop: { width: 1400 },
};

const desktopHorizontalPadding = 200;

const grid: Grids = {
  mobile: {
    maxWidth: `calc(100% - ${device.mobile.margin * 2}px)`,
    gutter: 16,
    columns: 2,
    columnWidth: 310,
  },
  tablet: {
    maxWidth: `calc(100% - ${device.tablet.margin * 2}px)`,
    gutter: 16,
    columns: 2,
    columnWidth: 310,
  },
  desktop: {
    maxWidth: device.desktop.width - desktopHorizontalPadding,
    gutter: 16,
    columns: 12,
    columnWidth: 48.5,
  },
  largeDesktop: {
    maxWidth: device.largeDesktop.width - desktopHorizontalPadding,
    gutter: 16,
    columns: 12,
    columnWidth: 85.25,
  },
};

const spacing = {
  xs: 8,
  sm: 16,
  md: 32,
  lg: 48,
  xl: 96,
  xxl: 182,
};

const sizes = {
  grid,
  spacing,
  contentTop: 50,
  footer: 50,
  query: {
    mobile: `@media (max-width: ${device.mobile.width}px)`,
    tablet: `@media (min-width: ${device.tablet.width}px)`,
    touch: `@media (max-width: ${device.touch.width}px)`,
    desktop: `@media (min-width: ${device.desktop.width}px)`,
    largeDesktop: `@media (min-width: ${device.largeDesktop.width}px)`,
  },
  toggle: {
    showMobile: `@media (max-width: ${device.mobile.width}px){display:block;}`,
    hideMobile: `@media (max-width: ${device.mobile.width}px){display:none;}`,
    showTouch: `@media (max-width: ${device.touch.width}px){display:block;}`,
    hideTouch: `@media (max-width: ${device.touch.width}px){display:none;}`,
    showDesktop: `@media (min-width: ${device.desktop.width}px){display:block;}`,
    hideDesktop: `@media (min-width: ${device.desktop.width}px){display:none;}`,
  },
};

export default sizes;
